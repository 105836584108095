<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="widget-content widget-content-area br-6">
            <div class="text-left">
              <button
                type="button"
                data-toggle="modal"
                data-target="#addModal"
                class="btn btn-success ml-auto float-right"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-user-plus"
                >
                  <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                  <circle cx="8.5" cy="7" r="4"></circle>
                  <line x1="20" y1="8" x2="20" y2="14"></line>
                  <line x1="23" y1="11" x2="17" y2="11"></line>
                </svg>
              </button>
            </div>

            <div v-if="loading">
              <b-skeleton-table
                :rows="10"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div v-else class="table-responsive">
              <table
                id="prospect_contacts"
                class="table table-hover non-hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Location</th>
                    <th>Note</th>
                    <th>Added on</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="prospect in prospects" :key="prospect.id">
                    <td>{{ prospect.name }}</td>
                    <td>{{ prospect.email }}</td>
                    <td>{{ prospect.phone }}</td>
                    <td>{{ prospect.location }}</td>
                    <td>{{ prospect.note }}</td>
                    <td>{{ formatDate(prospect.created_at) }}</td>
                    <td class="d-flex">
                      <button
                        v-if="prospect.calls.length > 0"
                        @click.prevent="showCalls(prospect.calls)"
                        class="btn btn-sm m-1 btn-outline-dark"
                      >
                        <i class="fa fa-phone"></i>
                      </button>
                      <button
                        @click.prevent="showEdit(prospect)"
                        class="btn btn-sm m-1 btn-outline-warning"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                      <button
                        @click.prevent="showDelete(prospect.id)"
                        class="btn btn-sm m-1 btn-outline-danger"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Modal -->
    <div
      class="modal fade"
      id="addModal"
      role="dialog"
      aria-labelledby="addModalTitle"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <i class="flaticon-cancel-12 close" data-dismiss="modal"></i>
            <div class="add-contact-box">
              <div class="add-contact-content">
                <div class="text-center">
                  <p>Add New Prospect</p>
                </div>
                <form id="addModalTitle">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="addModalName"> Name</label>
                        <input
                          type="text"
                          id="addModalName"
                          class="form-control"
                          v-model="newProspect.name"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="addModalEmail">Email (optional)</label>
                        <input
                          type="email"
                          id="addModalEmail"
                          class="form-control"
                          v-model="newProspect.email"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="addModalPhone">Phone Number </label>
                        <input
                          type="number"
                          id="addModalPhone"
                          class="form-control"
                          v-model="newProspect.phone"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="addModalLocation"
                          >Location (optional)
                        </label>
                        <input
                          type="text"
                          id="addModalLocation"
                          class="form-control"
                          v-model="newProspect.location"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="addModalNote">Note (optional)</label>
                        <textarea
                          class="form-control"
                          id="addModalNote"
                          rows="3"
                          v-model="newProspect.note"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-dark" data-dismiss="modal">
              <i class="flaticon-delete-1"></i> Discard
            </button>

            <button
              id="btn-add"
              @click.prevent="saveNewProspect()"
              class="btn btn-success"
              :disabled="loadingNew"
            >
              <b-spinner small v-if="loadingNew"></b-spinner>
              <span v-else>Save <i class="fa fa-paper-plane"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Modal -->
    <div
      class="modal fade"
      id="editModal"
      role="dialog"
      aria-labelledby="editModalTitle"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <i class="flaticon-cancel-12 close" data-dismiss="modal"></i>
            <div class="add-contact-box">
              <div class="add-contact-content">
                <div class="text-center">
                  <p>Edit Prospect Information</p>
                </div>
                <form id="editModalTitle">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="editModalName"> Name</label>
                        <input
                          type="text"
                          id="editModalName"
                          class="form-control"
                          v-model="editProspect.name"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="editModalEmail">Email (optional)</label>
                        <input
                          type="email"
                          id="editModalEmail"
                          class="form-control"
                          v-model="editProspect.email"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="editModalPhone">Phone Number </label>
                        <input
                          type="text"
                          id="editModalPhone"
                          class="form-control"
                          v-model="editProspect.phone"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="editModalLocation"
                          >Location (optional)
                        </label>
                        <input
                          type="text"
                          id="editModalLocation"
                          class="form-control"
                          v-model="editProspect.location"
                        />
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="editModalNote">Note (optional)</label>
                        <textarea
                          class="form-control"
                          id="editModalNote"
                          rows="3"
                          v-model="editProspect.note"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-dark" data-dismiss="modal">
              <i class="flaticon-delete-1"></i> Discard
            </button>

            <button
              id="btn-add"
              @click.prevent="saveEditProspect()"
              class="btn btn-success"
              :disabled="loadingEdit"
            >
              <b-spinner small v-if="loadingEdit"></b-spinner>
              <span v-else>Save Changes <i class="fa fa-paper-plane"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete  -->
    <div
      class="modal fade"
      id="deleteModal"
      role="dialog"
      aria-labelledby="editContactModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <i class="fa fa-close" data-dismiss="modal"></i>
            <div class="add-contact-box">
              <div class="add-contact-content">
                <div class="text-center">
                  <p class="text-danger">
                    Are you sure you want to delete this Prospect?
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">Cancel</button>

            <button
              @click.prevent="completeDelete()"
              class="btn btn-danger text-white"
              :disabled="loadingDelete"
            >
              <b-spinner small v-if="loadingDelete"></b-spinner>
              <span v-else>Confirm Delete <i class="fa fa-trash"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- calls modal -->
    <div
      class="modal fade"
      id="callsModal"
      role="dialog"
      aria-labelledby="callsModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="mr-auto">
              <small class="modal-title" id="callsModalLabel"
                >All Call Records</small
              >
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 col-12 layout-spacing">
                <div class="table-responsive">
                  <table class="table table-bordered mb-4">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Update</th>
                        <th>Date</th>
                        <th>Date to retry</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="call in callsToShow" :key="call.id">
                        <td>
                          {{ call.user.firstname || "" }}
                          {{ call.user.lastname || "" }}
                        </td>
                        <td>
                          <span
                            v-if="call.status == 'responded'"
                            class="btn btn-success"
                            >Responded</span
                          >
                          <span
                            v-if="call.status == 'unreachable'"
                            class="btn btn-info"
                            >Unreachable</span
                          >
                          <span
                            v-if="call.status == 'retry'"
                            class="btn btn-dark"
                            >Retry</span
                          >
                          <span
                            v-if="call.status == 'declined'"
                            class="btn btn-danger"
                            >Declined</span
                          >
                        </td>
                        <td>{{ call.update }}</td>
                        <td>{{ formatDate(call.created_at) }}</td>
                        <td>{{ formatDate(call.date_to_retry) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-dark ml-auto" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";
import { Prospect } from "@/models/prospect.js";
import $ from "jquery";

export default {
  name: "ProspectContacts",
  components: {},

  data() {
    return {
      loading: false,
      prospects: [],

      loadingNew: false,
      newProspect: new Prospect(),

      callsToShow: [],

      loadingEdit: false,
      editProspect: {},

      loadingDelete: false,
      deleteProspect: {},
    };
  },

  mounted() {
    this.fetchProspects();
  },

  methods: {
    initTable() {
      var dt = $("#prospect_contacts").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      dt.columns([3, 4, 5]).visible(false);
    },

    fetchProspects() {
      this.loading = true;
      http
        .get(endpoints.ALL_PROSPECTS)
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.prospects = response;
          setTimeout(() => {
            this.initTable();
          }, 1000);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    saveNewProspect() {
      if (this.newProspect.name == "" || this.newProspect.phone == "") {
        this.$toast.error("Missing input fields");
      } else {
        this.loadingNew = true;
        http
          .post(endpoints.NEW_PROSPECT, {
            name: this.newProspect.name,
            email: this.newProspect.email,
            phone: this.newProspect.phone,
            location: this.newProspect.location,
            note: this.newProspect.note,
          })
          .then((response) => {
            this.loadingNew = false;
            this.$toast.success(response);
            $("#addModal").modal("hide");
            this.newProspect = new Prospect();
            this.fetchProspects();
          })
          .catch((error) => {
            this.loadingNew = false;
            this.$toast.error(
              error.response?.data?.message || error.response?.message
            );
          });
      }
    },

    showCalls(x) {
      this.callsToShow = x;
      $("#callsModal").modal("show");
    },

    showEdit(x) {
      this.editProspect = x;
      $("#editModal").modal("show");
    },

    saveEditProspect() {
      if (this.editProspect.name == "" || this.editProspect.phone == "") {
        this.$toast.error("Missing input fields");
      } else {
        this.loadingEdit = true;
        http
          .put(endpoints.EDIT_PROSPECT, {
            id: this.editProspect.id,
            name: this.editProspect.name,
            email: this.editProspect.email,
            phone: this.editProspect.phone,
            location: this.editProspect.location,
            note: this.editProspect.note,
          })
          .then((response) => {
            this.loadingEdit = false;
            this.$toast.success(response);
            $("#editModal").modal("hide");
            this.editProspect = new Prospect();
            this.fetchProspects();
          })
          .catch((error) => {
            this.loadingEdit = false;
            this.$toast.error(
              error.response?.data?.message || error.response?.message
            );
          });
      }
    },

    showDelete(x) {
      this.deleteProspect = x;
      $("#deleteModal").modal("show");
    },

    completeDelete() {
      this.loadingDelete = true;
      http
        .delete(endpoints.DELETE_PROSPECT.replace(":id", this.deleteProspect))
        .then((response) => {
          console.log(response);
          this.loadingDelete = false;
          $("#deleteModal").modal("hide");
          this.$toast.success(response);
          this.fetchProspects();
        })
        .catch((error) => {
          console.log(error);
          this.loadingDelete = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
  },
};
</script>

<style scoped src="@/assets/css/tables/table-basic.css"></style>
